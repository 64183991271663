class TabManager {
  constructor() {
    this.tabButtons = document.querySelectorAll(".tab-button");
    this.tabPanes = document.querySelectorAll(".tab-pane");
    this.mobileSelect = document.querySelector(".tab-select-mobile");
    this.init();
  }

  init() {
    // Desktop tab buttons
    this.tabButtons.forEach((button) => {
      button.addEventListener("click", () =>
        this.switchTab(button.getAttribute("data-tab")),
      );
    });

    // Mobile dropdown
    if (this.mobileSelect) {
      this.mobileSelect.addEventListener("change", (e) => {
        this.switchTab(e.target.value);
      });
    }
  }

  switchTab(tabId) {
    // Remove active class from all buttons and panes
    this.tabButtons.forEach((button) => button.classList.remove("active"));
    this.tabPanes.forEach((pane) => pane.classList.remove("active"));

    // Add active class to selected button and corresponding pane
    const selectedButton = document.querySelector(`[data-tab="${tabId}"]`);
    if (selectedButton) {
      selectedButton.classList.add("active");
    }

    // Update mobile select if it exists
    if (this.mobileSelect) {
      this.mobileSelect.value = tabId;
    }

    // Show selected pane
    const selectedPane = document.getElementById(tabId);
    if (selectedPane) {
      selectedPane.classList.add("active");
    }
  }
}

// Initialize tabs when DOM is loaded
document.addEventListener("DOMContentLoaded", () => {
  new TabManager();
});
