$("select[multiple]").select2({
  width: "250px",
  placeholder: "Select options",
});

// Function to update the selection count display
function updateSelectionCount(element) {
  var uldiv = element.next(".select2").find(".select2-selection__rendered");
  var count = element.select2("data").length;

  if (count == 0) {
    uldiv.html(
      '<li class="select2-search select2-search--inline">' +
        '<input class="select2-search__field" type="search" tabindex="0" role="searchbox" placeholder="Select options">' +
        "</li>",
    ); // Show placeholder when no items selected
  } else {
    var text = count === 1 ? " item selected" : " items selected";
    uldiv.html("<li>" + count + text + "</li>"); // Show selected count with proper plural
  }
}

// Update count when Select2 closes
$("select[multiple]").on("select2:close", function (evt) {
  updateSelectionCount($(this));
});

// Update count on page load
$(function () {
  $("select[multiple]").each(function () {
    updateSelectionCount($(this));
  });
});
