document.addEventListener("DOMContentLoaded", function () {
  const messages = document.querySelectorAll(".messages li");

  messages.forEach((message) => {
    // Close button functionality
    const closeButton = message.querySelector(".message-close-btn");
    if (closeButton) {
      closeButton.addEventListener("click", function () {
        message.remove();
      });
    }

    // Automatically remove after 10 seconds
    setTimeout(() => {
      message.remove();
    }, 10000);
  });
});
