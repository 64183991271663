(function ($) {
  // Sliders
  $(".accordion-item__body").hide();
  $(".accordion-item__body").attr("aria-hidden", "true");
  $("a.accordion-item__title-container").addClass(
    "accordion-item__title-container--toggle-off"
  );

  /** Adding aria-control div id**/

  var itemNum = 1;
  $(".accordion-item").each(function () {
    var accordionIDName = "accordionietm-" + itemNum;
    $(this)
      .find(".accordion-item__title-container")
      .attr("aria-controls", accordionIDName);
    $(this).find(".accordion-item__body").attr("id", accordionIDName);
    itemNum++;
  });

  $("a.accordion-item__title-container").on("click", function () {
    var ele = $(this);
    // Prevent clicks upon animation
    if ($(".accordion-item__body :animated").length) return false;

    if ($(this).hasClass("accordion-item__title-container--toggle-on")) {
      $(this)
        .parent()
        .next(".accordion-item__body")
        .attr("aria-hidden", "true")
        .slideUp();
      $(this).removeClass("accordion-item__title-container--toggle-on");
      $(this).addClass("accordion-item__title-container--toggle-off");
      $(this).attr("aria-seleted", "false");
    } else {
      $(".accordion-item__body").slideUp();
      $(this)
        .parent()
        .next(".accordion-item__body")
        .attr("aria-hidden", "false")
        .slideDown({
          complete: function () {
            if (aboveViewport(ele)) {
              $("html, body").animate(
                {
                  scrollTop: ele.offset().top - 130,
                },
                200
              );
            }
          },
        });
      $("a.accordion-item__title-container").removeClass(
        "accordion-item__title-container--toggle-on"
      );
      $("a.accordion-item__title-container").addClass(
        "accordion-item__title-container--toggle-off"
      );
      $(this).removeClass("accordion-item__title-container--toggle-off");
      $(this).addClass("accordion-item__title-container--toggle-on");
      $(this).attr("aria-seleted", "true");
    }

    return false;
  });

  function aboveViewport(element) {
    if (element.length) {
      var win = $(window);
      var viewport = { top: win.scrollTop() };
      viewport.bottom = viewport.top + win.height();
      var bounds = element.offset();
      bounds.bottom = bounds.top + element.outerHeight();

      // Element bottom on or above the viewport
      if (viewport.top + 200 >= bounds.bottom) {
        return true;
      } else {
        return false;
      }
    }
  }
})($);
