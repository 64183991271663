(function ($) {
  // $ formatted selector to search for focusable items
  var focusableElementsString =
    "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";

  // store the item that has focus before opening the modal window
  var focusedElementBeforeModal;
  var formHasError = $(".accessible-modal.error").length;

  $("[data-toggle='modal']").click(function () {
    let id = $(this).attr("data-target");
    if ($(`#${id}`).length) {
      showModal($(`#${id}`));
    }
  });

  if (formHasError > 0) {
    let id = $(".accessible-modal.error").attr("id");
    showModal($(`#${id}`));
  }

  if ($(".accessible-modal.open").length > 0) {
    let id = $(".accessible-modal.open").attr("id");
    showModal($(`#${id}`));
  }

  $(".js-accessible-modal-close").click(function () {
    let modal = $(this).closest(".accessible-modal");
    hideModal(modal);
  });

  $(".accessible-modal").keydown(function (event) {
    trapTabKey($(this), event);
  });

  $("body").keydown(function (event) {
    trapEscapeKey($(this), event);
  });

  function trapEscapeKey(obj, evt) {
    // if escape pressed
    if (evt.which == 27) {
      // get list of all children elements in given object
      var o = obj.find("*");

      // get list of focusable items
      var cancelElement;
      cancelElement = o.filter(".js-accessible-modal-close");

      // close the modal window
      cancelElement.click();
      evt.preventDefault();
    }
  }

  function trapTabKey(obj, evt) {
    // if tab or shift-tab pressed
    if (evt.which == 9) {
      // get list of all children elements in given object
      var o = obj.find("*");

      // get list of focusable items
      var focusableItems;
      focusableItems = o.filter(focusableElementsString).filter(":visible");

      // get currently focused item
      var focusedItem;
      focusedItem = $(":focus");

      // get the number of focusable items
      var numberOfFocusableItems;
      numberOfFocusableItems = focusableItems.length;
      // get the index of the currently focused item
      var focusedItemIndex;
      focusedItemIndex = focusableItems.index(focusedItem);

      if (evt.shiftKey) {
        //back tab
        // if focused on first item and user preses back-tab, go to the last focusable item
        if (focusedItemIndex == 0) {
          focusableItems.get(numberOfFocusableItems - 1).focus();
          evt.preventDefault();
        }
      } else {
        //forward tab
        // if focused on the last item and user preses tab, go to the first focusable item
        if (focusedItemIndex == numberOfFocusableItems - 1) {
          focusableItems.get(0).focus();
          evt.preventDefault();
        }
      }
    }
  }

  function setFocusToFirstItemInModal(obj) {
    // get list of all children elements in given object
    var o = obj.find("*");

    // set the focus to the first keyboard focusable item
    o.filter(focusableElementsString).filter(":visible").first().focus();
  }

  function showModal(obj) {
    $("#modalOverlay").css("display", "block"); // insert an overlay to prevent clicking and make a visual change to indicate the main apge is not available
    obj.addClass("open"); // make the modal window visible
    obj.attr("aria-hidden", "false"); // mark the modal window as visible

    // save current focus
    focusedElementBeforeModal = $(":focus");
    setFocusToFirstItemInModal(obj);
  }

  function hideModal() {
    $("#modalOverlay").css("display", "none"); // remove the overlay in order to make the main screen available again
    $(".accessible-modal").removeClass("open"); // hide the modal window
    $(".accessible-modal").attr("aria-hidden", "true"); // mark the modal window as hidden

    // set focus back to element that had it before the modal was opened
    focusedElementBeforeModal.focus();
    if (formHasError) {
      var url = window.location.href;
      window.location.href = url;
    }
  }
})($);
