import $ from "jquery";
window.jQuery = $;
window.$ = $;

require("./shadow-scroller");
import "./accordion-menu";
import "./dataGrid";
import "./modal";
import "./form";
import "./accordion";
import "./secondary-menu";
import "./fileUploadWarning";
import "./language-select";
import "./hamburger-menu";
import "./message";
import "./fileUpload";
import "./tab";
import "./multiselect";
(function ($) {
  "use strict";

  var site = (window.site = {});

  site.elements = {
    menu: $(".menu-hamburger"),
    sideMenu: $(".menu-side"),
    timeoutWarning: $("#timeout-warning"), // Inactivity timeout modal
    continueBtn: $("#continue-btn"), // Continue button for inactivity
    whistleblowerTimeoutWarning: $("#whistleblower-timeout-warning"), // Whistleblower form timeout modal
    whistleblowerContinueBtn: $("#whistleblower-continue-btn"), // Continue button for Whistleblower inactivity
  };

  site.getBreakpoint = (function () {
    var breakpoint;

    function getBreakpointContent() {
      return window
        .getComputedStyle(document.body, ":after")
        .content.toLowerCase()
        .replace(/"/g, "");
    }

    breakpoint = getBreakpointContent();
    $(window).resize(function () {
      breakpoint = getBreakpointContent();
    });

    return function () {
      return breakpoint;
    };
  })();

  /* Generate overlay */
  site.showDrawerOverlay = function () {
    $("body").addClass("toggled-drawer-offset-overlay");
  };

  site.hideDrawerOverlay = function () {
    $("body").removeClass("toggled-drawer-offset-overlay");
  };

  /* End of Generating overlay */

  /* Initializing shadow scroller */
  site.shadowScroller = function () {
    $(".table-scroller table").shadowScroller();
  };

  /* End of initializing shadow scroller */

  /* Reset rs form submit button when clicking outside reCAPTCHA */
  site.recaptcha = function () {
    $(document).mouseup(function (e) {
      var iframe = $('iframe[title="recaptcha"]');

      if (!iframe.is(e.target) && iframe.has(e.target).length === 0) {
        $(".rsform-submit-button").each(function () {
          if ($(this).prop("disabled")) {
            $(this).removeProp("disabled");
          }
        });
      }
    });
  };

  /* End of reset rs form submit button */

  /* Initializing dropdown menu */
  site.accordionMenu = function (menu) {
    $(menu).accordionMenu({
      addIndicator: true,
      onlyOneShown: true,
      autoScroll: true,
    });
  };

  /* End of Initializing dropdown menu */

  /* Inactivity Timeout Function */
  site.inactivityTimeout = function () {
    let timeoutSetting = document
      .getElementById("timeout-warning")
      .getAttribute("data-inactivity-timeout");
    let enableTimeout = timeoutSetting === "True" || timeoutSetting === "true"; // Convert to boolean

    if (!enableTimeout) {
      return;
    }
    let isLoggedIn = document.body.getAttribute("data-is-logged-in");

    if (isLoggedIn == "false") {
      return;
    }
    let inactivityTime = 7 * 60 * 1000; // 7 minutes
    let warningTime = 45 * 1000; // 45 seconds
    let warningTimer, logoutTimer;

    function resetTimer() {
      clearTimeout(warningTimer);
      clearTimeout(logoutTimer);
      startInactivityTimer();
    }

    function startInactivityTimer() {
      warningTimer = setTimeout(showWarning, inactivityTime);
    }

    function showWarning() {
      console.log("[Inactivity] 7 minutes of inactivity - Showing warning.");
      $("#timeout-warning").removeClass("hidden").addClass("visible");

      // Start countdown to logout (form submission)
      logoutTimer = setTimeout(() => {
        console.log("[Inactivity] No response - Auto-submitting logout form.");
        window.location.href = "/users/user-logout/"; // Auto-submit form
      }, warningTime);
    }

    function continueSession() {
      site.elements.timeoutWarning.addClass("hidden");
      site.elements.timeoutWarning.removeClass("visible");
      resetTimer();
    }

    $(document).on("mousemove keydown scroll click", resetTimer);
    site.elements.continueBtn.on("click", continueSession);

    startInactivityTimer();
  };

  /* Added: Whistleblower Form Inactivity Timeout */
  site.whistleblowerInactivityTimeout = function () {
    let timeoutSetting = document
      .getElementById("whistleblower-timeout-warning")
      .getAttribute("data-inactivity-timeout");
    let enableTimeout = timeoutSetting === "True" || timeoutSetting === "true"; // Convert to boolean
    ``;
    if (!enableTimeout) {
      return;
    }

    let isLoggedIn = document.body.getAttribute("data-is-logged-in");
    if (!isLoggedIn === "True") {
      return;
    }

    let inactivityTime = 7 * 60 * 1000; // 7 minutes
    let warningTime = 45 * 1000; // 45 seconds
    let warningTimer, redirectTimer;

    // Extract full redirect URL from the warning modal
    let landingPageURL =
      site.elements.whistleblowerTimeoutWarning.attr("data-org-url");

    function resetTimer() {
      clearTimeout(warningTimer);
      clearTimeout(redirectTimer);
      startInactivityTimer();
    }

    function startInactivityTimer() {
      warningTimer = setTimeout(showWarning, inactivityTime);
    }

    function showWarning() {
      site.elements.whistleblowerTimeoutWarning
        .removeClass("hidden")
        .addClass("visible");

      redirectTimer = setTimeout(() => {
        window.location.href = landingPageURL;
      }, warningTime);
    }

    function continueSession() {
      site.elements.whistleblowerTimeoutWarning.addClass("hidden");
      site.elements.whistleblowerTimeoutWarning.removeClass("visible");
      resetTimer();
    }

    $("#whistleblower-form").on(
      "mousemove keydown input click scroll",
      resetTimer,
    );
    site.elements.whistleblowerContinueBtn.on("click", continueSession);

    startInactivityTimer();
  };

  /* Initialize all site functions */
  site.init = function () {
    site.shadowScroller();
    site.recaptcha();
    site.accordionMenu(site.elements.menu);
    site.accordionMenu(site.elements.sideMenu);
    site.inactivityTimeout(); // Initialize inactivity timeout

    // ✅ Run Whistleblower inactivity timeout only if body has "public-form-page"
    if ($("body").hasClass("public-form-page")) {
      site.whistleblowerInactivityTimeout();
    }
  };

  // Document ready
  $(function () {
    site.init();
  });
})($);
