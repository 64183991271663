(function ($) {
  if ($("#id_additional_files").length) {
    return; // Exit if the file upload input is not present on the page
  }
  var pendingFile = null;
  var warningModal = $("#warning-modal");
  var cancelBtn = $("#cancel-btn");
  var proceedBtn = $("#proceed-btn");

  $('input[type="file"]').on("change", function (event) {
    if ($(this).attr("id") !== "id_logo_upload") {
      pendingFile = event.target.files[0]; // Store selected file
      warningModal.removeClass("hidden").addClass("visible"); // Show modal
    }
  });

  // Cancel file upload
  cancelBtn.on("click", function () {
    warningModal.addClass("hidden");
    $('input[type="file"]').val(""); // Clear file input
    pendingFile = null;
  });

  // Proceed with upload
  proceedBtn.on("click", function () {
    warningModal.addClass("hidden");
  });
})($);
