/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 */
/**
 * @namespace aria
 */

var aria = aria || {};

/**
 * @desc
 *  Key code constants
 */
aria.KeyCode = {
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46,
};

aria.Utils = aria.Utils || {};

// Polyfill src https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
aria.Utils.matches = function (element, selector) {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = element.parentNode.querySelectorAll(s);
        var i = matches.length;
        return i > -1;
      };
  }

  return element.matches(selector);
};

aria.Utils.remove = function (item) {
  if (item.remove && typeof item.remove === "function") {
    return item.remove();
  }
  if (
    item.parentNode &&
    item.parentNode.removeChild &&
    typeof item.parentNode.removeChild === "function"
  ) {
    return item.parentNode.removeChild(item);
  }
  return false;
};

aria.Utils.isFocusable = function (element) {
  if (
    element.tabIndex > 0 ||
    (element.tabIndex === 0 && element.getAttribute("tabIndex") !== null)
  ) {
    return true;
  }

  if (element.disabled) {
    return false;
  }

  switch (element.nodeName) {
    case "A":
      return !!element.href && element.rel != "ignore";
    case "INPUT":
      return element.type != "hidden" && element.type != "file";
    case "BUTTON":
    case "SELECT":
    case "TEXTAREA":
      return true;
    default:
      return false;
  }
};

aria.Utils.getAncestorBySelector = function (element, selector) {
  if (!aria.Utils.matches(element, selector + " " + element.tagName)) {
    // Element is not inside an element that matches selector
    return null;
  }

  // Move up the DOM tree until a parent matching the selector is found
  var currentNode = element;
  var ancestor = null;
  while (ancestor === null) {
    if (aria.Utils.matches(currentNode.parentNode, selector)) {
      ancestor = currentNode.parentNode;
    } else {
      currentNode = currentNode.parentNode;
    }
  }

  return ancestor;
};

aria.Utils.hasClass = function (element, className) {
  return new RegExp("(\\s|^)" + className + "(\\s|$)").test(element.className);
};

aria.Utils.addClass = function (element, className) {
  if (!aria.Utils.hasClass(element, className)) {
    element.className += " " + className;
  }
};

aria.Utils.removeClass = function (element, className) {
  var classRegex = new RegExp("(\\s|^)" + className + "(\\s|$)");
  element.className = element.className.replace(classRegex, " ").trim();
};

aria.Utils.bindMethods = function (object /* , ...methodNames */) {
  var methodNames = Array.prototype.slice.call(arguments, 1);
  methodNames.forEach(function (method) {
    object[method] = object[method].bind(object);
  });
};

/**
 * ARIA Menu Button example
 * @function onload
 * @desc  after page has loaded initialize all menu buttons based on the selector "[aria-haspopup][aria-controls]"
 */

window.addEventListener("load", function () {
  var menuButtons = document.querySelectorAll("[aria-haspopup][aria-controls]");

  [].forEach.call(menuButtons, function (menuButton) {
    if (
      (menuButton && menuButton.tagName.toLowerCase() === "button") ||
      menuButton.getAttribute("role").toLowerCase() === "button"
    ) {
      var mb = new aria.widget.MenuButton(menuButton);
      mb.initMenuButton();
    }
  });
});

/* ---------------------------------------------------------------- */
/*                  ARIA Utils Namespace                        */
/* ---------------------------------------------------------------- */

/**
 * @constructor Menu
 *
 * @memberOf aria.Utils
 *
 * @desc  Computes absolute position of an element
 */

aria.Utils = aria.Utils || {};

aria.Utils.findPos = function (element) {
  var xPosition = 0;
  var yPosition = 0;

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }
  return { x: xPosition, y: yPosition };
};

/* ---------------------------------------------------------------- */
/*                  ARIA Widget Namespace                        */
/* ---------------------------------------------------------------- */

aria.widget = aria.widget || {};

/* ---------------------------------------------------------------- */
/*                  Menu Button Widget                           */
/* ---------------------------------------------------------------- */

/**
 * @constructor Menu
 *
 * @memberOf aria.Widget
 *
 * @desc  Creates a Menu Button widget using ARIA
 */

aria.widget.Menu = function (node, menuButton) {
  this.keyCode = Object.freeze({
    TAB: 9,
    RETURN: 13,
    ESC: 27,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
  });

  // Check fo DOM element node
  if (typeof node !== "object" || !node.getElementsByClassName) {
    return false;
  }

  this.menuNode = node;
  node.tabIndex = -1;

  this.menuButton = menuButton;

  this.firstMenuItem = false;
  this.lastMenuItem = false;
};

/**
 * @method initMenuButton
 *
 * @memberOf aria.widget.Menu
 *
 * @desc  Adds event handlers to button elements
 */

aria.widget.Menu.prototype.initMenu = function () {
  var self = this;

  var cn = this.menuNode.firstChild;

  while (cn) {
    if (cn.nodeType === Node.ELEMENT_NODE) {
      if (cn.getAttribute("role") === "menuitem") {
        cn.tabIndex = -1;
        if (!this.firstMenuItem) {
          this.firstMenuItem = cn;
        }
        this.lastMenuItem = cn;

        var eventKeyDown = function (event) {
          self.eventKeyDown(event, self);
        };
        cn.addEventListener("keydown", eventKeyDown);

        var eventMouseClick = function (event) {
          self.eventMouseClick(event, self);
        };
        cn.addEventListener("click", eventMouseClick);

        var eventBlur = function (event) {
          self.eventBlur(event, self);
        };
        cn.addEventListener("blur", eventBlur);

        var eventFocus = function (event) {
          self.eventFocus(event, self);
        };
        cn.addEventListener("focus", eventFocus);
      }
    }
    cn = cn.nextSibling;
  }
};

/**
 * @method nextMenuItem
 *
 * @memberOf aria.widget.Menu
 *
 * @desc  Moves focus to next menuItem
 */

aria.widget.Menu.prototype.nextMenuItem = function (currentMenuItem) {
  var mi = currentMenuItem.nextSibling;

  while (mi) {
    if (
      mi.nodeType === Node.ELEMENT_NODE &&
      mi.getAttribute("role") === "menuitem"
    ) {
      mi.focus();
      break;
    }
    mi = mi.nextSibling;
  }

  if (!mi && this.firstMenuItem) {
    this.firstMenuItem.focus();
  }
};

/**
 * @method previousMenuItem
 *
 * @memberOf aria.widget.Menu
 *
 * @desc  Moves focus to previous menuItem
 */

aria.widget.Menu.prototype.previousMenuItem = function (currentMenuItem) {
  var mi = currentMenuItem.previousSibling;

  while (mi) {
    if (
      mi.nodeType === Node.ELEMENT_NODE &&
      mi.getAttribute("role") === "menuitem"
    ) {
      mi.focus();
      break;
    }
    mi = mi.previousSibling;
  }

  if (!mi && this.lastMenuItem) {
    this.lastMenuItem.focus();
  }
};

/**
 * @method eventKeyDown
 *
 * @memberOf aria.widget.Menu
 *
 * @desc  Keydown event handler for Menu Object
 *        NOTE: The menu parameter is needed to provide a reference to the specific
 *               menu
 */

aria.widget.Menu.prototype.eventKeyDown = function (event, menu) {
  var ct = event.currentTarget;
  var flag = false;

  switch (event.keyCode) {
    case menu.keyCode.SPACE:
    case menu.keyCode.RETURN:
      menu.eventMouseClick(event, menu);
      menu.menuButton.closeMenu(true);
      flag = true;
      break;

    case menu.keyCode.ESC:
      menu.menuButton.closeMenu(true);
      menu.menuButton.buttonNode.focus();
      flag = true;
      break;

    case menu.keyCode.UP:
    case menu.keyCode.LEFT:
      menu.previousMenuItem(ct);
      flag = true;
      break;

    case menu.keyCode.DOWN:
    case menu.keyCode.RIGHT:
      menu.nextMenuItem(ct);
      flag = true;
      break;

    case menu.keyCode.TAB:
      menu.menuButton.closeMenu(true, false);
      break;

    default:
      break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

/**
 * @method eventMouseClick
 *
 * @memberOf aria.widget.Menu
 *
 * @desc  onclick event handler for Menu Object
 *        NOTE: The menu parameter is needed to provide a reference to the specific
 *               menu
 */

aria.widget.Menu.prototype.eventMouseClick = function (event, menu) {
  var clickedItemText = event.target.innerText;
  this.menuButton.buttonNode.innerText = clickedItemText;
  menu.menuButton.closeMenu(true);
};

/**
 * @method eventBlur
 *
 * @memberOf aria.widget.Menu
 *
 * @desc  eventBlur event handler for Menu Object
 *        NOTE: The menu parameter is needed to provide a reference to the specific
 *               menu
 */
aria.widget.Menu.prototype.eventBlur = function (event, menu) {
  menu.menuHasFocus = false;
  setTimeout(function () {
    if (!menu.menuHasFocus) {
      menu.menuButton.closeMenu(false, false);
    }
  }, 200);
};

/**
 * @method eventFocus
 *
 * @memberOf aria.widget.Menu
 *
 * @desc  eventFoucs event handler for Menu Object
 *        NOTE: The menu parameter is needed to provide a reference to the specific
 *               menu
 */
aria.widget.Menu.prototype.eventFocus = function (event, menu) {
  menu.menuHasFocus = true;
};

/* ---------------------------------------------------------------- */
/*                  Menu Button Widget                           */
/* ---------------------------------------------------------------- */

/**
 * @constructor Menu Button
 *
 * @memberOf aria.Widget
 *
 * @desc  Creates a Menu Button widget using ARIA
 */

aria.widget.MenuButton = function (node) {
  this.keyCode = Object.freeze({
    TAB: 9,
    RETURN: 13,
    ESC: 27,
    SPACE: 32,
    UP: 38,
    DOWN: 40,
  });

  // Check fo DOM element node
  if (typeof node !== "object" || !node.getElementsByClassName) {
    return false;
  }

  this.done = true;
  this.mouseInMouseButton = false;
  this.menuHasFocus = false;
  this.buttonNode = node;
  this.isLink = false;

  if (node.tagName.toLowerCase() === "a") {
    var url = node.getAttribute("href");
    if (url && url.length && url.length > 0) {
      this.isLink = true;
    }
  }
};

/**
 * @method initMenuButton
 *
 * @memberOf aria.widget.MenuButton
 *
 * @desc  Adds event handlers to button elements
 */

aria.widget.MenuButton.prototype.initMenuButton = function () {
  var id = this.buttonNode.getAttribute("aria-controls");

  if (id) {
    this.menuNode = document.getElementById(id);

    if (this.menuNode) {
      this.menu = new aria.widget.Menu(this.menuNode, this);
      this.menu.initMenu();
      this.menuShowing = false;
    }
  }

  this.closeMenu(false, false);

  var self = this;

  var eventKeyDown = function (event) {
    self.eventKeyDown(event, self);
  };
  this.buttonNode.addEventListener("keydown", eventKeyDown);

  var eventMouseClick = function (event) {
    self.eventMouseClick(event, self);
  };
  this.buttonNode.addEventListener("click", eventMouseClick);
};

/**
 * @method openMenu
 *
 * @memberOf aria.widget.MenuButton
 *
 * @desc  Opens the menu
 */

aria.widget.MenuButton.prototype.openMenu = function () {
  if (this.menuNode) {
    this.menuNode.style.display = "block";
    this.menuShowing = true;
  }
};

/**
 * @method closeMenu
 *
 * @memberOf aria.widget.MenuButton
 *
 * @desc  Close the menu
 */

aria.widget.MenuButton.prototype.closeMenu = function (force, focusMenuButton) {
  if (typeof force !== "boolean") {
    force = false;
  }
  if (typeof focusMenuButton !== "boolean") {
    focusMenuButton = true;
  }

  if (
    force ||
    (!this.mouseInMenuButton &&
      this.menuNode &&
      !this.menu.mouseInMenu &&
      !this.menu.menuHasFocus)
  ) {
    this.menuNode.style.display = "none";
    if (focusMenuButton) {
      this.buttonNode.focus();
    }
    this.menuShowing = false;
  }
};

/**
 * @method toggleMenu
 *
 * @memberOf aria.widget.MenuButton
 *
 * @desc  Close or open the menu depending on current state
 */

aria.widget.MenuButton.prototype.toggleMenu = function () {
  if (this.menuNode) {
    if (this.menuNode.style.display === "block") {
      this.menuNode.style.display = "none";
    } else {
      this.menuNode.style.display = "block";
    }
  }
};

/**
 * @method moveFocusToFirstMenuItem
 *
 * @memberOf aria.widget.MenuButton
 *
 * @desc  Move keyboard focus to first menu item
 */

aria.widget.MenuButton.prototype.moveFocusToFirstMenuItem = function () {
  if (this.menu.firstMenuItem) {
    this.openMenu();
    this.menu.firstMenuItem.focus();
  }
};

/**
 * @method moveFocusToLastMenuItem
 *
 * @memberOf aria.widget.MenuButton
 *
 * @desc  Move keyboard focus to last menu item
 */

aria.widget.MenuButton.prototype.moveFocusToLastMenuItem = function () {
  if (this.menu.lastMenuItem) {
    this.openMenu();
    this.menu.lastMenuItem.focus();
  }
};

/**
 * @method eventKeyDown
 *
 * @memberOf aria.widget.MenuButton
 *
 * @desc  Keydown event handler for MenuButton Object
 *        NOTE: The menuButton parameter is needed to provide a reference to the specific
 *               menuButton
 */

aria.widget.MenuButton.prototype.eventKeyDown = function (event, menuButton) {
  var flag = false;

  switch (event.keyCode) {
    case menuButton.keyCode.SPACE:
      menuButton.moveFocusToFirstMenuItem();
      flag = true;
      break;

    case menuButton.keyCode.RETURN:
      menuButton.moveFocusToFirstMenuItem();
      flag = true;
      break;

    case menuButton.keyCode.UP:
      if (this.menuShowing) {
        menuButton.moveFocusToLastMenuItem();
        flag = true;
      }
      break;

    case menuButton.keyCode.DOWN:
      if (this.menuShowing) {
        menuButton.moveFocusToFirstMenuItem();
        flag = true;
      }
      break;

    case menuButton.keyCode.TAB:
      menuButton.closeMenu(true, false);
      break;

    default:
      break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

/**
 * @method eventMouseClick
 *
 * @memberOf aria.widget.MenuButton
 *
 * @desc  Click event handler for MenuButton Object
 *        NOTE: The menuButton parameter is needed to provide a reference to the specific
 *               menuButton
 */
aria.widget.MenuButton.prototype.eventMouseClick = function (
  event,
  menuButton
) {
  menuButton.moveFocusToFirstMenuItem();
};

/**
 * @desc
 *  Values for aria-sort
 */
aria.SortType = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
  NONE: "none",
};

/**
 * @desc
 *  DOM Selectors to find the grid components
 */
aria.GridSelector = {
  ROW: 'tr, [role="row"]',
  CELL: 'th, td, [role="gridcell"]',
  SCROLL_ROW: 'tr:not([data-fixed]), [role="row"]',
  SORT_HEADER: "th[aria-sort]",
  TABBABLE: '[tabindex="0"]',
};

/**
 * @desc
 *  CSS Class names
 */
aria.CSSClass = {
  HIDDEN: "hidden",
};

/**
 * @constructor
 *
 * @desc
 *  Grid object representing the state and interactions for a grid widget
 *
 *  Assumptions:
 *  All focusable cells initially have tabindex="-1"
 *  Produces a fully filled in mxn grid (with no holes)
 *
 * @param gridNode
 *  The DOM node pointing to the grid
 */
aria.Grid = function (gridNode) {
  this.navigationDisabled = false;
  this.gridNode = gridNode;
  this.paginationEnabled = this.gridNode.hasAttribute("data-per-page");
  this.shouldWrapCols = this.gridNode.hasAttribute("data-wrap-cols");
  this.shouldWrapRows = this.gridNode.hasAttribute("data-wrap-rows");
  this.shouldRestructure = this.gridNode.hasAttribute("data-restructure");
  this.topIndex = 0;

  this.keysIndicator = document.getElementById("arrow-keys-indicator");

  aria.Utils.bindMethods(
    this,
    "checkFocusChange",
    "delegateButtonHandler",
    "focusClickedCell",
    "showKeysIndicator",
    "hideKeysIndicator"
  );
  this.setupFocusGrid();
  this.setFocusPointer(0, 0);

  this.perPage = this.grid.length;

  this.registerEvents();
};

/**
 * @desc
 *  Creates a 2D array of the focusable cells in the grid.
 */
aria.Grid.prototype.setupFocusGrid = function () {
  this.grid = [];

  Array.prototype.forEach.call(
    this.gridNode.querySelectorAll(aria.GridSelector.ROW),
    function (row) {
      var rowCells = [];

      Array.prototype.forEach.call(
        row.querySelectorAll(aria.GridSelector.CELL),
        function (cell) {
          var focusableSelector = "[tabindex]";

          if (aria.Utils.matches(cell, focusableSelector)) {
            rowCells.push(cell);
          } else {
            var focusableCell = cell.querySelector(focusableSelector);

            if (focusableCell) {
              rowCells.push(focusableCell);
            }
          }
        }.bind(this)
      );

      if (rowCells.length) {
        this.grid.push(rowCells);
      }
    }.bind(this)
  );
};

/**
 * @desc
 *  If possible, set focus pointer to the cell with the specified coordinates
 *
 * @param row
 *  The index of the cell's row
 *
 * @param col
 *  The index of the cell's column
 *
 * @returns
 *  Returns whether or not the focus could be set on the cell.
 */
aria.Grid.prototype.setFocusPointer = function (row, col) {
  if (!this.isValidCell(row, col)) {
    return false;
  }

  if (this.isHidden(row, col)) {
    return false;
  }

  if (!isNaN(this.focusedRow) && !isNaN(this.focusedCol)) {
    this.grid[this.focusedRow][this.focusedCol].setAttribute("tabindex", -1);
  }

  this.grid[row][col].removeEventListener("focus", this.showKeysIndicator);
  this.grid[row][col].removeEventListener("blur", this.hideKeysIndicator);

  // Disable navigation if focused on an input
  this.navigationDisabled = aria.Utils.matches(this.grid[row][col], "input");

  this.grid[row][col].setAttribute("tabindex", 0);
  this.focusedRow = row;
  this.focusedCol = col;

  this.grid[row][col].addEventListener("focus", this.showKeysIndicator);
  this.grid[row][col].addEventListener("blur", this.hideKeysIndicator);

  return true;
};

/**
 * @param row
 *  The index of the cell's row
 *
 * @param col
 *  The index of the cell's column
 *
 * @returns
 *  Returns whether or not the coordinates are within the grid's boundaries.
 */
aria.Grid.prototype.isValidCell = function (row, col) {
  return (
    !isNaN(row) &&
    !isNaN(col) &&
    row >= 0 &&
    col >= 0 &&
    this.grid &&
    this.grid.length &&
    row < this.grid.length &&
    col < this.grid[row].length
  );
};

/**
 * @param row
 *  The index of the cell's row
 *
 * @param col
 *  The index of the cell's column
 *
 * @returns
 *  Returns whether or not the cell has been hidden.
 */
aria.Grid.prototype.isHidden = function (row, col) {
  var cell = this.gridNode
    .querySelectorAll(aria.GridSelector.ROW)
    [row].querySelectorAll(aria.GridSelector.CELL)[col];
  return aria.Utils.hasClass(cell, aria.CSSClass.HIDDEN);
};

/**
 * @desc
 *  Clean up grid events
 */
aria.Grid.prototype.clearEvents = function () {
  this.gridNode.removeEventListener("keydown", this.checkFocusChange);
  this.gridNode.removeEventListener("keydown", this.delegateButtonHandler);
  this.gridNode.removeEventListener("click", this.focusClickedCell);
  this.gridNode.removeEventListener("click", this.delegateButtonHandler);

  this.grid[this.focusedRow][this.focusedCol].removeEventListener(
    "focus",
    this.showKeysIndicator
  );
  this.grid[this.focusedRow][this.focusedCol].removeEventListener(
    "blur",
    this.hideKeysIndicator
  );
};

/**
 * @desc
 *  Register grid events
 */
aria.Grid.prototype.registerEvents = function () {
  this.clearEvents();

  this.gridNode.addEventListener("keydown", this.checkFocusChange);
  this.gridNode.addEventListener("keydown", this.delegateButtonHandler);
  this.gridNode.addEventListener("click", this.focusClickedCell);
  this.gridNode.addEventListener("click", this.delegateButtonHandler);
};

/**
 * @desc
 *  Focus on the cell in the specified row and column
 *
 * @param row
 *  The index of the cell's row
 *
 * @param col
 *  The index of the cell's column
 */
aria.Grid.prototype.focusCell = function (row, col) {
  if (this.setFocusPointer(row, col)) {
    this.grid[row][col].focus();
  }
};

aria.Grid.prototype.showKeysIndicator = function () {
  if (this.keysIndicator) {
    aria.Utils.removeClass(this.keysIndicator, "hidden");
  }
};

aria.Grid.prototype.hideKeysIndicator = function () {
  if (
    this.keysIndicator &&
    this.grid[this.focusedRow][this.focusedCol].tabIndex === 0
  ) {
    aria.Utils.addClass(this.keysIndicator, "hidden");
  }
};

/**
 * @desc
 *  Triggered on keydown. Checks if an arrow key was pressed, and (if possible)
 *  moves focus to the next valid cell in the direction of the arrow key.
 *
 * @param event
 *  Keydown event
 */
aria.Grid.prototype.checkFocusChange = function (event) {
  if (!event || this.navigationDisabled) {
    return;
  }

  this.findFocusedItem(event.target);

  var key = event.which || event.keyCode;
  var rowCaret = this.focusedRow;
  var colCaret = this.focusedCol;
  var nextCell;

  switch (key) {
    case aria.KeyCode.UP:
      nextCell = this.getNextVisibleCell(0, -1);
      rowCaret = nextCell.row;
      colCaret = nextCell.col;
      break;
    case aria.KeyCode.DOWN:
      nextCell = this.getNextVisibleCell(0, 1);
      rowCaret = nextCell.row;
      colCaret = nextCell.col;
      break;
    case aria.KeyCode.LEFT:
      nextCell = this.getNextVisibleCell(-1, 0);
      rowCaret = nextCell.row;
      colCaret = nextCell.col;
      break;
    case aria.KeyCode.RIGHT:
      nextCell = this.getNextVisibleCell(1, 0);
      rowCaret = nextCell.row;
      colCaret = nextCell.col;
      break;
    case aria.KeyCode.HOME:
      if (event.ctrlKey) {
        rowCaret = 0;
      }
      colCaret = 0;
      break;
    case aria.KeyCode.END:
      if (event.ctrlKey) {
        rowCaret = this.grid.length - 1;
      }
      colCaret = this.grid[this.focusedRow].length - 1;
      break;
    default:
      return;
  }

  if (this.paginationEnabled) {
    if (rowCaret < this.topIndex) {
      this.showFromRow(rowCaret, true);
    }

    if (rowCaret >= this.topIndex + this.perPage) {
      this.showFromRow(rowCaret, false);
    }
  }

  this.focusCell(rowCaret, colCaret);
  event.preventDefault();
};

/**
 * @desc
 *  Reset focused row and col if it doesn't match focusedRow and focusedCol
 *
 * @param focusedTarget
 *  Element that is currently focused by browser
 */
aria.Grid.prototype.findFocusedItem = function (focusedTarget) {
  var focusedCell = this.grid[this.focusedRow][this.focusedCol];

  if (focusedCell === focusedTarget || focusedCell.contains(focusedTarget)) {
    return;
  }

  for (var i = 0; i < this.grid.length; i++) {
    for (var j = 0; j < this.grid[i].length; j++) {
      if (
        this.grid[i][j] === focusedTarget ||
        this.grid[i][j].contains(focusedTarget)
      ) {
        this.setFocusPointer(i, j);
        return;
      }
    }
  }
};

/**
 * @desc
 *  Triggered on click. Finds the cell that was clicked on and focuses on it.
 *
 * @param event
 *  Keydown event
 */
aria.Grid.prototype.focusClickedCell = function (event) {
  var clickedGridCell = this.findClosest(event.target, "[tabindex]");

  for (var row = 0; row < this.grid.length; row++) {
    for (var col = 0; col < this.grid[row].length; col++) {
      if (this.grid[row][col] === clickedGridCell) {
        this.setFocusPointer(row, col);

        if (!aria.Utils.matches(clickedGridCell, "button[aria-haspopup]")) {
          // Don't focus if it's a menu button (focus should be set to menu)
          this.focusCell(row, col);
        }

        return;
      }
    }
  }
};

/**
 * @desc
 *  Triggered on click. Checks if user clicked on a header with aria-sort.
 *  If so, it sorts the column based on the aria-sort attribute.
 *
 * @param event
 *  Keydown event
 */
aria.Grid.prototype.delegateButtonHandler = function (event) {
  var key = event.which || event.keyCode;
  var target = event.target;
  var isClickEvent = event.type === "click";

  if (!target) {
    return;
  }

  if (
    target.parentNode &&
    target.parentNode.matches("th[aria-sort]") &&
    (isClickEvent || key === aria.KeyCode.SPACE || key === aria.KeyCode.RETURN)
  ) {
    event.preventDefault();
    this.handleSort(target.parentNode);
  }
};

/**
 * @desc
 *  Scroll the specified row into view in the specified direction
 *
 * @param startIndex
 *  Row index to use as the start index
 *
 * @param scrollDown
 *  Whether to scroll the new page above or below the row index
 */
aria.Grid.prototype.showFromRow = function (startIndex, scrollDown) {
  var dataRows = this.gridNode.querySelectorAll(aria.GridSelector.SCROLL_ROW);
  var reachedTop = false;
  var firstIndex = -1;

  if (startIndex < 0 || startIndex >= dataRows.length) {
    return;
  }

  for (var i = 0; i < dataRows.length; i++) {
    if (
      (scrollDown && i >= startIndex && i < startIndex + this.perPage) ||
      (!scrollDown && i <= startIndex && i > startIndex - this.perPage)
    ) {
      aria.Utils.removeClass(dataRows[i], aria.CSSClass.HIDDEN);

      if (!reachedTop) {
        this.topIndex = i;
        reachedTop = true;
      }

      if (firstIndex < 0) {
        firstIndex = i;
      }
    } else {
      aria.Utils.addClass(dataRows[i], aria.CSSClass.HIDDEN);
    }
  }
};

/**
 * @desc
 *  Get next cell to the right or left (direction) of the focused
 *  cell.
 *
 * @param currRow
 *  Row index to start searching from
 *
 * @param currCol
 *  Column index to start searching from
 *
 * @param directionX
 *  X direction for where to check for cells. +1 to check to the right, -1 to
 *  check to the left
 *
 * @return
 *  Indices of the next cell in the specified direction. Returns the focused
 *  cell if none are found.
 */
aria.Grid.prototype.getNextCell = function (
  currRow,
  currCol,
  directionX,
  directionY
) {
  var row = currRow + directionY;
  var col = currCol + directionX;
  var rowCount = this.grid.length;
  var isLeftRight = directionX !== 0;

  if (!rowCount) {
    return false;
  }

  var colCount = this.grid[0].length;

  if (this.shouldWrapCols && isLeftRight) {
    if (col < 0) {
      col = colCount - 1;
      row--;
    }

    if (col >= colCount) {
      col = 0;
      row++;
    }
  }

  if (this.shouldWrapRows && !isLeftRight) {
    if (row < 0) {
      col--;
      row = rowCount - 1;
      if (this.grid[row] && col >= 0 && !this.grid[row][col]) {
        // Sometimes the bottom row is not completely filled in. In this case,
        // jump to the next filled in cell.
        row--;
      }
    } else if (row >= rowCount || !this.grid[row][col]) {
      row = 0;
      col++;
    }
  }

  if (this.isValidCell(row, col)) {
    return {
      row: row,
      col: col,
    };
  } else if (this.isValidCell(currRow, currCol)) {
    return {
      row: currRow,
      col: currCol,
    };
  } else {
    return false;
  }
};

/**
 * @desc
 *  Get next visible column to the right or left (direction) of the focused
 *  cell.
 *
 * @param direction
 *  Direction for where to check for cells. +1 to check to the right, -1 to
 *  check to the left
 *
 * @return
 *  Indices of the next visible cell in the specified direction. If no visible
 *  cells are found, returns false if the current cell is hidden and returns
 *  the current cell if it is not hidden.
 */
aria.Grid.prototype.getNextVisibleCell = function (directionX, directionY) {
  var nextCell = this.getNextCell(
    this.focusedRow,
    this.focusedCol,
    directionX,
    directionY
  );

  if (!nextCell) {
    return false;
  }

  while (this.isHidden(nextCell.row, nextCell.col)) {
    var currRow = nextCell.row;
    var currCol = nextCell.col;

    nextCell = this.getNextCell(currRow, currCol, directionX, directionY);

    if (currRow === nextCell.row && currCol === nextCell.col) {
      // There are no more cells to try if getNextCell returns the current cell
      return false;
    }
  }

  return nextCell;
};

/**
 * @desc
 *  Find the closest element matching the selector. Only checks parent and
 *  direct children.
 *
 * @param element
 *  Element to start searching from
 *
 * @param selector
 *  Index of the column to toggle
 */
aria.Grid.prototype.findClosest = function (element, selector) {
  if (aria.Utils.matches(element, selector)) {
    return element;
  }

  if (aria.Utils.matches(element.parentNode, selector)) {
    return element.parentNode;
  }

  return element.querySelector(selector);
};
