document.addEventListener("DOMContentLoaded", function () {
  const languageButton = document.querySelector(".language-dropdown__label");
  const languageDropdown = document.querySelector(".language-dropdown__list");

  if (!languageButton || !languageDropdown) return; // Exit if elements don't exist

  // Toggle dropdown when clicking the button
  languageButton.addEventListener("click", function () {
    languageDropdown.classList.toggle("show");
  });

  // Close dropdown when clicking outside
  document.addEventListener("click", function (event) {
    if (!event.target.closest(".language-dropdown")) {
      languageDropdown.classList.remove("show");
    }
  });
});
