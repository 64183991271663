function active(element) {
  element.closest(".fieldInputWrapper").addClass("active");
  element.addClass("active");
}

function inactive(element) {
  element.closest(".fieldInputWrapper").removeClass("active");
  element.removeClass("active");
}

function removeError(element) {
  element.removeClass("invalid");
  element.closest(".fieldWrapper").find(".errorlist").css("display", "none");
}

(function init() {
  let inputSelector =
    "input[type=date], input[type=text], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=number], input[type=search], textarea, select";

  $(inputSelector).each(function (index, element) {
    if (
      ($(element).val() && $(element).val().length > 0) ||
      element.autofocus
    ) {
      active($(element));
    }
  });

  $(inputSelector).on("blur", function () {
    if ($(this).val().length > 0) {
      active($(this));
    } else {
      inactive($(this));
    }
  });

  $(inputSelector).on("focus", function () {
    active($(this));
  });

  $(inputSelector).on("blur", function () {
    if ($(this).hasClass("invalid")) {
      removeError($(this));
    }
  });

  if ($(".fieldWrapper .invalid").length > 0) {
    let firstInvalidInput = $(".fieldWrapper .invalid").first();
    let top = firstInvalidInput.offset().top;
    // The first error is the one with minium offset to top
    $(".fieldWrapper .invalid").each(function () {
      let topTmp = $(this).offset().top;
      if (topTmp < top) {
        top = topTmp;
        firstInvalidInput = $(this);
      }
    });
  }
})();
