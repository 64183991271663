document.addEventListener("DOMContentLoaded", function () {
  // Constants for selectors
  const SELECTORS = {
    menuToggle: ".internal-menu .internal-menu__toggle",
    menu: ".internal-menu ul",
    activeMenuItem: ".internal-menu__item.active a",
    currentPage: ".internal-menu .current-page",
  };

  // Class names
  const CLASSES = {
    active: "is-active",
  };

  // Helper functions
  const getElement = (selector) => document.querySelector(selector);

  const toggleMenu = (menuToggle, menu, isOpen) => {
    menuToggle.setAttribute("aria-expanded", isOpen);
    menu.classList.toggle(CLASSES.active, isOpen);
  };

  const updateCurrentPageText = () => {
    const activeMenuItem = getElement(SELECTORS.activeMenuItem);
    const currentPageSpan = getElement(SELECTORS.currentPage);

    if (activeMenuItem && currentPageSpan) {
      currentPageSpan.textContent = activeMenuItem.textContent.trim();
    }
  };

  // Initialize mobile menu
  const initMobileMenu = () => {
    const menuToggle = getElement(SELECTORS.menuToggle);
    const menu = getElement(SELECTORS.menu);

    if (!menuToggle || !menu) {
      console.warn("Mobile menu elements not found");
      return;
    }

    // Toggle menu on button click
    menuToggle.addEventListener("click", (event) => {
      event.stopPropagation();
      const isExpanded = menuToggle.getAttribute("aria-expanded") === "true";
      toggleMenu(menuToggle, menu, !isExpanded);
    });

    // Close menu when clicking outside
    document.addEventListener("click", (event) => {
      const isClickOutside =
        !menu.contains(event.target) && !menuToggle.contains(event.target);

      if (isClickOutside && menu.classList.contains(CLASSES.active)) {
        toggleMenu(menuToggle, menu, false);
      }
    });

    // Close menu on escape key
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && menu.classList.contains(CLASSES.active)) {
        toggleMenu(menuToggle, menu, false);
      }
    });
  };

  // Initialize everything
  try {
    initMobileMenu();
    updateCurrentPageText();
  } catch (error) {
    console.error("Error initializing secondary menu:", error);
  }
});
