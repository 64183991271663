(function ($) {
  var site = (window.site = {});

  site.elements = {
    menu: $(".menu-hamburger"),
    btnMenu: $(".btn-menu"),
    hamburgerMenu: $(".mobile-menu"),
    pageHead: "page-head",
  };

  /* Generate overlay */
  site.showDrawerOverlay = function () {
    $("body").addClass("toggled-drawer-offset-overlay");
  };

  site.hideDrawerOverlay = function () {
    $("body").removeClass("toggled-drawer-offset-overlay");
  };

  /* End of Generating overlay */

  /* Hamburger menu */
  site.hamburgerMenu = {
    isActive: false,

    close: function () {
      site.elements.hamburgerMenu.removeClass("active");
      site.elements.btnMenu.removeClass("active");
      $(".page-head").removeClass("active");
      site.hideDrawerOverlay();
      this.isActive = false;
    },

    open: function () {
      site.elements.hamburgerMenu.addClass("active");
      site.elements.btnMenu.addClass("active");
      $(".page-head").addClass("active");
      site.showDrawerOverlay();
      this.isActive = true;
    },

    init: function () {
      var that = this;

      site.elements.btnMenu.click(function (e) {
        e.preventDefault();
        that.isActive ? that.close() : that.open();
      });

      $(document).on("click touchend", function (e) {
        if (
          !$(e.target).closest("." + site.elements.pageHead).length &&
          that.isActive
        ) {
          that.close();
        }
      });

      $(window).on("resize", function () {
        if ($(window).width() > 850 && that.isActive) {
          that.close();
        }
      });
    },
  };

  /* Initialize all site functions */
  site.init = function () {
    site.hamburgerMenu.init();
  };

  // Document ready
  $(function () {
    site.init();
  });
})($);
