(function ($) {
  const disclosureForm = $(".storeFilesInIndexedDB");
  let fileInput = $("#id_additional_files");
  let pendingFile = null;
  let confirmedFiles = new DataTransfer();
  let selectedFiles = new DataTransfer();
  const warningModal = $("#warning-modal");
  const cancelBtn = $("#cancel-btn");
  const proceedBtn = $("#proceed-btn");
  let filesList;
  window.validFiles = [];

  function initializeFileInput(fileInput) {
    if (!fileInput.length) return;

    const fieldWrapper = fileInput.closest(".fieldWrapper");

    if (!filesList) {
      fieldWrapper
        .find(".fieldInputWrapper")
        .after('<div id="selected-files-list" class="mt-2"></div>');
      filesList = $("#selected-files-list");
    }

    fileInput.on("change", async function () {
      const files = this.files;

      if (files.length > 0) {
        let fileArray = [];
        const existingFiles = JSON.parse(
          sessionStorage.getItem("document_uploads") || "[]",
        );

        const allowedTypes = [
          // Image formats
          "image/jpeg", // .jpg, .jpeg
          "image/png", // .png

          // Video formats
          "video/mp4", // .mp4
          "video/quicktime", // .mov
          "video/x-matroska", // .mkv

          "video/x-msvideo", // .avi
          "video/avi", // .avi (alternative MIME type) (Edge)
          "video/msvideo", // .avi (alternative MIME type) (Safari)
          "video/x-ms-video", // .avi (alternative MIME type) (Firefox)
          "video/vnd.avi", // .avi (alternative MIME type) (Chrome)

          // Audio formats
          "audio/mpeg", // .mp3

          // Document formats
          "application/pdf", // .pdf

          // Microsoft Word formats
          "application/msword", // .doc
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx

          // Microsoft Excel formats
          "application/vnd.ms-excel", // .xls
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx

          // Microsoft PowerPoint formats
          "application/vnd.ms-powerpoint", // .ppt
          "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx

          // OpenDocument formats (LibreOffice/OpenOffice)
          "application/vnd.oasis.opendocument.text", // .odt
          "application/vnd.oasis.opendocument.spreadsheet", // .ods
          "application/vnd.oasis.opendocument.presentation", // .odp
        ];

        const MAX_FILE_SIZE = 30000000;
        let invalidFiles = [];

        for (let file of files) {
          if (!allowedTypes.includes(file.type)) {
            invalidFiles.push(`${file.name}: Invalid file type.`);
            continue;
          }

          if (file.size > MAX_FILE_SIZE) {
            invalidFiles.push(`${file.name}: File is too large.`);
            continue;
          }

          fileArray.push({ name: file.name, type: file.type, size: file.size });
          window.validFiles.push(file);
        }

        if (invalidFiles.length > 0) {
          alert(invalidFiles.join("\n"));
        }

        if (window.validFiles.length > 0) {
          pendingFile = new DataTransfer();
          window.validFiles.forEach((file) => pendingFile.items.add(file));
          this.files = pendingFile.files;
          warningModal.show();
        } else {
          this.value = "";
        }
      }
    });
  }

  function watchForFileInput() {
    new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (
            $(node).is("#id_additional_files") ||
            $(node).find("#id_additional_files").length
          ) {
            fileInput = $("#id_additional_files");
            initializeFileInput(fileInput);
          }
        });
      });
    }).observe(document.body, { childList: true, subtree: true });
  }

  function updateFilesList(files) {
    if (!filesList) return;
    filesList.empty();
    files.forEach((file) => {
      filesList.append(`
        <div class="file-item mb-2">
          <span>${file.name}</span>
          <button type="button" class="btn btn-secondary btn-sm ml-2 delete-file" data-filename="${file.name}">
            Delete
          </button>
        </div>
      `);
    });
  }

  initializeFileInput(fileInput);
  watchForFileInput();

  $(document).on("click", ".delete-file", function () {
    const fileName = $(this).data("filename");
    window.validFiles = window.validFiles.filter(
      (file) => file.name !== fileName,
    );
    updateFilesList(window.validFiles);
  });

  (async function () {
    if (
      disclosureForm.length &&
      disclosureForm.find("#id_additional_files").length
    ) {
      await clearIndexedDB();
      await initIndexedDB();
    }
  })();

  async function clearIndexedDB() {
    return new Promise((resolve) => {
      const request = indexedDB.deleteDatabase("FileStorage");

      request.onsuccess = function () {
        resolve();
      };

      request.onerror = function () {
        resolve();
      };
    });
  }

  async function initIndexedDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("FileStorage", 1);

      request.onupgradeneeded = function (event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("files")) {
          db.createObjectStore("files", { keyPath: "name" });
        }
      };

      request.onsuccess = function (event) {
        const db = event.target.result;
        resolve(db);
      };

      request.onerror = function () {
        reject("Failed to open IndexedDB.");
      };
    });
  }

  // Function to store multiple files in IndexedDB
  async function storeFilesInIndexedDB(files) {
    try {
      const db = await initIndexedDB();

      for (const file of files) {
        // Read file as ArrayBuffer (smaller than Base64)
        const arrayBufferData = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });

        const transaction = db.transaction("files", "readwrite");
        const store = transaction.objectStore("files");

        const fileData = {
          name: file.name,
          type: file.type,
          size: file.size,
          data: arrayBufferData, // Store as binary ArrayBuffer
        };

        await new Promise((resolve, reject) => {
          const request = store.put(fileData);
          request.onsuccess = () => {
            console.log(`Stored file in IndexedDB: ${file.name}`);
            resolve();
          };
          request.onerror = (event) => {
            console.error(
              "Error storing file in IndexedDB:",
              event.target.error,
            );
            reject(event.target.error);
          };
        });
      }

      console.log("✅ All files stored successfully.");
    } catch (error) {
      console.error("❌ Failed to store files:", error);
      throw error;
    }
  }

  cancelBtn.on("click", function () {
    warningModal.hide();
    if (pendingFile) {
      // Get the pending file names
      const pendingFileNames = Array.from(pendingFile.files).map(
        (file) => file.name,
      );

      // Remove pending files from window.validFiles only
      window.validFiles = window.validFiles.filter(
        (file) => !pendingFileNames.includes(file.name),
      );

      pendingFile.value = ""; // Clear only the pending file input
      // Keep the previously confirmed files, don't reset confirmedFiles/selectedFiles
      updateFilesList(Array.from(confirmedFiles.files));
      updateFileInput();
    }
    pendingFile = null;
  });

  proceedBtn.on("click", async function () {
    warningModal.hide();
    if (pendingFile) {
      try {
        const newFiles = Array.from(pendingFile.files);

        // Store files in IndexedDB only when disclosure form is present
        if (
          disclosureForm.length &&
          disclosureForm.find("#id_additional_files").length
        ) {
          for (const file of newFiles) {
            await storeFilesInIndexedDB([file]);
          }
        }

        // Add to confirmed files if not already present
        for (const file of newFiles) {
          if (
            !Array.from(confirmedFiles.files).some(
              (f) => f.name === file.name && f.size === file.size,
            )
          ) {
            confirmedFiles.items.add(file);
            selectedFiles.items.add(file);
          }
        }

        // Update session storage with all confirmed files
        const fileArrayForStorage = Array.from(confirmedFiles.files).map(
          (file) => ({
            name: file.name,
            type: file.type,
            size: file.size,
          }),
        );
        sessionStorage.setItem(
          "document_uploads",
          JSON.stringify(fileArrayForStorage),
        );

        updateFilesList(Array.from(confirmedFiles.files));
        updateFileInput();
      } catch (error) {
        console.error("Error processing files:", error);
        alert("Failed to process files. Please try again.");
      } finally {
        pendingFile = null;
      }
    }
  });

  function updateFileInput() {
    const newFileList = new DataTransfer();
    Array.from(confirmedFiles.files).forEach((file) =>
      newFileList.items.add(file),
    );
    fileInput[0].files = newFileList.files;
  }

  $(document).on("click", ".delete-file", async function () {
    const fileName = $(this).data("filename");
    let fileArray = Array.from(confirmedFiles.files).filter(
      (file) => file.name !== fileName,
    );
    confirmedFiles = new DataTransfer();
    selectedFiles = new DataTransfer();

    // Remove from window.validFiles
    window.validFiles = window.validFiles.filter(
      (file) => file.name !== fileName,
    );

    // Add remaining files back to both DataTransfer objects
    fileArray.forEach((file) => {
      confirmedFiles.items.add(file);
      selectedFiles.items.add(file);
    });

    // Update IndexedDB
    try {
      const db = await initIndexedDB();
      const transaction = db.transaction("files", "readwrite");
      const store = transaction.objectStore("files");
      await store.delete(fileName);

      // Update the session storage to match
      const fileArrayForStorage = fileArray.map((file) => ({
        name: file.name,
        type: file.type,
        size: file.size,
      }));
      sessionStorage.setItem(
        "document_uploads",
        JSON.stringify(fileArrayForStorage),
      );
    } catch (error) {
      console.error("Error deleting file from IndexedDB:", error);
    }

    updateFilesList(fileArray);
    updateFileInput();
  });
})($);
